<template>
<div class="w-full max-w-xs mx-auto my-8">

  <form @submit.prevent="login" class="bg-white xshadow-md rounded px-8 pt-6 pb-8 mb-4">

    <div class="mb-4">
      <label class="block text-grey-darker text-sm font-bold mb-2">Username</label>
      <input v-model="email" class="shadow appearance-none border xrounded w-full py-2 px-3 text-grey-darker leading-normal focus:outline-none focus:shadow-outline" type="text" autocomplete="username" />
    </div>

    <div class="mb-6">
      <label class="block text-grey-darker text-sm font-bold mb-2">Password</label>
      <input v-model="password" class="shadow appearance-none border xborder-red xrounded w-full py-2 px-3 text-grey-darker mb-3 leading-normal focus:outline-none focus:shadow-outline" type="password" autocomplete="current-password" />
    </div>

    <div v-if="error && error.message" class="text-red mb-6">{{ error.message }}</div>

    <div class="flex items-center justify-between">
      <button class="btn-telkom-blue-dark" type="submit">Log In</button>
    </div>

  </form>

</div>
</template>

<script>

import { sdk } from '@/sdk.js'

import { mapState, mapMutations } from 'vuex'

export default {

  name: 'Login',

  data () {

    return {

        email: '',
        password: ''

    }

  },

  computed: {

    ...mapState(['error'])

  },

  methods: {

    ...mapMutations(['setError']),

    login() {

        let login = {
            email: this.email,
            password: this.password
        };

        this.setError({});

        sdk.login(login)

        .then((resp) => {


            if (resp.error && resp.error.message) {

                // this.setError(resp.error || { message: 'There was an error!' });
                this.setError({ message: resp.error.message });

            } else if (resp.data) {

                localStorage.setItem('auth', JSON.stringify(resp.data));

                this.email = '';
                this.password = '';

                this.setError({});

                this.$router.replace({ name: 'home'});

                location.reload();
            }

        });

    }
  }
}
</script>
